body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ui.celled.table tr td:first-child,
.ui.celled.table tr th:first-child {
  border-left: 1px solid rgba(34, 36, 38, 0.1);
} */
/* .ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: 1px solid rgba(34, 36, 38, 0.1);
} */
/* table {
  border-collapse: collapse !important;
  border-inline-style: hidden !important;
}
td,
th {
  border-inline-style: 1px solid rgba(34, 36, 38, 0.1) !important;
} */

.wallpaper-thumb {
  width: 20%;
  height: 20%;
}
.wallpaper-thumb-a {
  border: 0px solid #000;
}

#menu-margin {
  margin-bottom: 100px;
}
@media only screen and (max-width: 767px) {
  #menu-margin {
    margin-bottom: 300px;
  }
}
